<template>
  <component :is="defineAsyncComponent(() => import('~layers/app/pages/~cms.vue'))">
    
  </component>
</template>

<script setup lang="ts">
import layoutQuery from '~layers/app/graphql/layout.gql'

const meta = inject('meta', ref()) // probably empty

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale, status: 'PUBLISHED' })

const { searchSnippet } = useSeo()
searchSnippet()

const meta2 = computed(() => {
  return {
    id: `strapi-${data.value?.page404?.[0]?.documentId}`,
    url: '',
    page_type: 'cms-page',
    strapi_id: data.value?.page404?.[0]?.documentId || '',
    label: '404',
  }
})

provide('meta', meta2)
</script>
